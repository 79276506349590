export default class FinancaOperacoesService {
  constructor(http) {
    this._http = http
  }

  async gerarOperacao(dados) {
    const { data } = await this._http.post(`/api/financas/gerar`, dados)
    return data
  }

  async listarHistoricoOperacoes() {
    const { data } = await this._http.get(`/api/financas/`)
    return data
  }

  async existeProcessamentoAtivo() {
    const { data } = await this._http.get(`/api/financas/em_processamento`)
    return data
  }
}
